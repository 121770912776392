/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Madera-Bold";
  src: url("./fonts/Monotype-MaderaBold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Monotype-MaderaBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Monotype-MaderaBold.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Monotype-MaderaBold.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Monotype-MaderaBold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Monotype-MaderaBold.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Monotype-MaderaBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Madera-Medium";
  src: url("./fonts/Monotype-MaderaMedium.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Monotype-MaderaMedium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Monotype-MaderaMedium.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Monotype-MaderaMedium.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Monotype-MaderaMedium.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Monotype-MaderaMedium.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Monotype-MaderaMedium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Madera-Thin";
  src: url("./fonts/Monotype-MaderaThin.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Monotype-MaderaThin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Monotype-MaderaThin.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Monotype-MaderaThin.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Monotype-MaderaThin.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Monotype-MaderaThin.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Monotype-MaderaThin.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Madera-Regular";
  src: url("./fonts/Monotype-MaderaRegular.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Monotype-MaderaRegular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Monotype-MaderaRegular.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Monotype-MaderaRegular.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Monotype-MaderaRegular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Monotype-MaderaRegular.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Monotype-MaderaRegular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;

}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Madera-Bold';

  background: rgb(94,203,219);
  background: linear-gradient(135deg, rgba(94,203,219,1) 0%, rgba(94,203,219,1) 20%, rgba(255,123,85,1) 38.5%, rgba(255,123,85,1) 40.5%, rgba(94,203,219,1) 59%, rgba(94,203,219,1) 61%, rgba(219,94,119,1) 80%, rgba(219,94,119,1) 100%);
  background-size: 800% 800%;

  animation: Gradient 30s infinite;
  -webkit-animation: Gradient 30s infinite;
	-moz-animation: Gradient 30s infinite;
	animation: Gradient 30s infinite;

}

#root {
  height: 100%;
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}



/*
body {
	width: 100wh;
	height: 90vh;
	color: #fff;
	background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
	background-size: 400% 400%;
	-webkit-animation: Gradient 15s ease infinite;
	-moz-animation: Gradient 15s ease infinite;
	animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

h1,
h6 {
	font-family: 'Open Sans';
	font-weight: 300;
	text-align: center;
	position: absolute;
	top: 45%;
	right: 0;
	left: 0;
}


body {
  background-color: #FAF0E6;
}
*/
