.App {
  min-height: 100%;
  align-items: center;
  text-align: center;
  padding-top: 200px;
  color: #FFF;
  box-sizing: border-box;
  border: solid 16px #FFF;
}

.App-logo {
  max-height: 140px;
}

h1 {
    font-size: 0px;
    margin: 0;
}

.footer {
  color: #fff;
  font-size: 21px;
  letter-spacing: 0.5px;
  margin-top: -8px;
  margin-left: -20px;
  margin-bottom: 30px;
  font-family: 'Madera-Medium';
}

.footer .spacer {
  margin: 29px;
}

.arrows {
  margin-top: 96px;
  margin-bottom: 64px;
}

.arrow {
  height: 31px;
  width: 54px;
  margin: -6px auto;
  background-image: url(./img/arrow_white.svg);
  animation: Arrow
}

.cta {
  margin-bottom: 0px;
  font-size: 28px;
  color: #FFF;
}

.overline {
  font-family: 'Madera-Regular';
  font-size: 12px;
  margin: 0px;
  margin-bottom: 40px;
}

.emailBig {
  display: inline;
}

.emailSmall {
  display: none;
}

a {
  text-decoration: none;
  color: #FFF;
}

.one {
  animation-delay: 0.25;
  animation: One 2s infinite;
}

@keyframes One {
  0% {
		opacity: 0;
	}
  20% {
		opacity: 0;
	}
  40% {
    opacity: 0.25;
  }
  60% {
    opacity: 0.25;
  }
  80% {
    opacity: 0.25;
  }
	100% {
		opacity: 0.25;
	}
}

.two {
  animation-delay: 0.25;
  animation: Two 2s infinite;
}

@keyframes Two {
  0% {
		opacity: 0;
	}
  20% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0.45;
  }
  80% {
    opacity: 0.45;
  }
	100% {
		opacity: 0.45;
	}
}

.three {
  animation-delay: 0.25;
  animation: Three 2s infinite;
}

@keyframes Three {
  0% {
		opacity: 0;
	}
  20% {
		opacity: 0;
	}
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0.65;
  }
  100% {
    opacity: 0.65;
  }
}

.four {
  animation-delay: 0.25;
  animation: Four 2s infinite;
}

@keyframes Four {
	0% {
		opacity: 0;
	}
  20% {
		opacity: 0;
	}
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
	100% {
		opacity: 0.8;
	}
}

@media all and (max-width: 1280px) {
  .App-logo {
    max-height: 115px;
  }
  .footer {
    font-size: 18px;
  }

  .footer .spacer {
    margin: 18px;
  }
}
@media all and (max-width: 980px) {
  .App {
    padding-top: 160px;
    border: solid 12px #FFF;
  }
  .App-logo {
    max-height: 85px;
  }
  .footer {
    font-size: 15px;
    letter-spacing: 0.1px;
    margin-left: -12px;
  }

  .footer .spacer {
    margin: 6px;
  }
}
@media all and (max-width: 736px) {
  .App {
    padding-top: 100px;
    border: solid 8px #FFF;
  }
  .App-logo {
    max-height: 55px;
  }
  .footer {
    margin-top: 12px;
    font-size: 16px;
    letter-spacing: 0.1px;
    margin-left: -12px;
    font-family: 'Madera-Bold';
  }
  .footer .footerItem {
    margin-top: 0px;
    display: block;
  }
  .footer .spacer {
    display: none;
  }

  .footer .footerItem.emailBig {
    display: none;
  }

  .emailSmall {
    display: block;
  }

  .arrows {
    margin-top: 72px;
    margin-bottom: 44px;
  }

  .cta {
    font-size: 24px;
  }
}
